import React, {useState, useRef} from 'react';
import ScrollBarShowLayout from 'components/ScrollBarShowLayout';
import Seo from 'components/seo';
import Layout from 'layouts/zh';
import VideoPlayer from 'components/VideoPlayer';
import { postToTask } from 'api/onlineDemo';
import classnames from 'classnames';
import useLoginDialog from 'hooks/useLoginDialog';
import { saTrackOnlineDemoButtonClick, saTrackOnlineDemoLoginClick } from 'utils/statistics';
import { useStaticQuery, graphql } from 'gatsby';
import * as styles from './index.module.less';

const OnlineHealthCare = () => {
  const [user, setUser] = useState(null)
  const curVideo = useRef<any>(null)
  
  const { detailsYaml } = useStaticQuery(graphql`
    query {
      detailsYaml {
        onlineHealthCare_overall
        overall_catalogo_mb
        onlineHealthCare {
          saTitle
          title
          description
          keywords
          watchVideo
          experienceDemo
          experienceKPI
          viewData {
            link
            text
          }
        }
        finger_matting
        right_icon
      }
    }
  `)

  const { onlineHealthCare_overall,overall_catalogo_mb,  onlineHealthCare, finger_matting, right_icon } = detailsYaml

    // 登录弹窗hook
  const { show: showLoginDialog } = useLoginDialog({
    // 登录成功后回调
    onSuccess: () => {
      saTrackOnlineDemoLoginClick(onlineHealthCare?.saTitle);
      (window.location.href = '/democenter/onlinehealthcare.html')
    },
  })

  const handleLink = (url, button_name) => {
    saTrackOnlineDemoButtonClick(button_name)
    url && window.open(url);
  }

  const handleToTask = (type: string) => {
    console.log(onlineHealthCare?.experienceDemo)
    if (!user) {
     return showLoginDialog()
    }

    switch (type) {
      case 'experienceDemo':
        postToTask({name: '体验行业Demo'});
        handleLink(onlineHealthCare?.experienceDemo, '体验行业Demo')
        break;
      case 'experienceKPI':
        postToTask({name: '体验指标拆解工具'});
        handleLink(onlineHealthCare?.experienceKPI, '体验指标拆解工具')
        break;
      case 'watchVideo':
        // @ts-ignore
        curVideo.current.playVideo();
        postToTask({name: '观看讲解视频'});
        handleLink(false, '观看讲解视频')
        break;
      default:
    }
  }

  const RenderHealthCare = (
    <div className={styles.onlineHealthCare}>
      <img width='1920px' src={onlineHealthCare_overall} />
        <VideoPlayer
          videoOpt={{
            sources: onlineHealthCare?.watchVideo,
          }}
          fuRef={curVideo}
        />
        <div className={classnames(styles.clickArea, styles.watchVideo)} onClick={() => handleToTask('watchVideo')}>
          <div className={styles.guidance}>
            <img width="90px" className={styles.guidanceLogo} src={finger_matting} />
          </div>
        </div>
        <div className={classnames(styles.clickArea, styles.experienceDemo)} onClick={() => handleToTask('experienceDemo')}>
          <div className={styles.guidance}>
            <img width="90px" className={styles.guidanceLogo} src={finger_matting} />
          </div>
        </div>
        <div className={classnames(styles.clickArea, styles.viewData)}>
          <div className={styles.guidance}>
            <img width="90px" className={styles.guidanceLogo} src={finger_matting} />
          </div>
          <div className={classnames(styles.descContent)}>
            <div className={classnames(styles.descBg, styles.position_left, styles.hoverShow)} style={{ backgroundImage: `url(${right_icon})` }}>
              <ul className={styles.content}>
                {onlineHealthCare?.viewData?.map(item => (
                  <li key={item?.link} onClick={() => {
                    if (!user) {
                      return showLoginDialog()
                    }
                    if (!item?.link) {
                      return
                    }
                    postToTask({ name: '查看干货资料' });
                    handleLink(item?.link, '查看干货资料')
                  }}>
                    <span className={styles.contentHover}>
                      {item?.text}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className={classnames(styles.clickArea, styles.experienceKPI)} onClick={() => handleToTask('experienceKPI')}>
           <div className={styles.guidance}>
            <img width="90px" className={styles.guidanceLogo} src={finger_matting} />
          </div>
        </div>
      </div>
  )
  return (
    <Layout showFloat={false} showCookieAuth={false} hideFooter={true}>
      <Seo
        title={onlineHealthCare?.title}
        description={onlineHealthCare?.description}
        keywords={onlineHealthCare?.keywords}
        saTitle={onlineHealthCare?.saTitle}
      />
      <div className={classnames('block lg:hidden')}>
        <img width='100%' src={overall_catalogo_mb} />
      </div>
      <ScrollBarShowLayout className={classnames('hidden lg:block')} limitWidth={1920} innerH={937} childrenEle={RenderHealthCare} getUser={(user) => setUser(user)}/>
    </Layout> 
  )
}

export default OnlineHealthCare;