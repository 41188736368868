// extracted by mini-css-extract-plugin
export var clickArea = "index-module--clickArea--zwVKS";
export var content = "index-module--content--Fztm9";
export var contentHover = "index-module--contentHover--tVO6T";
export var descBg = "index-module--descBg--krsg7";
export var descContent = "index-module--descContent--iY1yf";
export var experienceDemo = "index-module--experienceDemo--IoAC-";
export var experienceKPI = "index-module--experienceKPI--tr371";
export var guidance = "index-module--guidance--OW-gj";
export var guidanceLogo = "index-module--guidanceLogo---NsNK";
export var hoverShow = "index-module--hoverShow--rK443";
export var hoverUp = "index-module--hover-up--vWYm4";
export var onlineHealthCare = "index-module--onlineHealthCare--KMaAG";
export var position_left = "index-module--position_left--iicRS";
export var viewData = "index-module--viewData--ZF227";
export var watchVideo = "index-module--watchVideo--LKLLE";